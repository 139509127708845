<template>
  <div>
    <v-form @submit.prevent="submitResponse">
      <p class="mb-3">
        Perform each of the unit conversions shown below. Be sure to express your answer to the
        appropriate number of significant figures.
      </p>

      <p class="mb-2">
        a) <number-value :value="number1" unit="\text{mg}" />
        <stemble-latex content="$\text{ to g}$" />
      </p>
      <calculation-input v-model="inputs.input1" class="mb-4" :disabled="!allowEditing" />

      <p class="mb-2">
        b) <number-value :value="number2" unit="\text{m}" />
        <stemble-latex content="$\text{ to pm}$" />
      </p>
      <calculation-input v-model="inputs.input2" class="mb-4" :disabled="!allowEditing" />

      <p class="mb-2">
        c) <number-value :value="number3" unit="\text{mm}" />
        <stemble-latex content="$\text{ to }\mu\text{m}$" />
      </p>
      <calculation-input v-model="inputs.input3" class="mb-4" :disabled="!allowEditing" />

      <p class="mb-2">
        d) <number-value :value="number4" unit="\text{g}" />
        <stemble-latex content="$\text{ to kg}$" />
      </p>
      <calculation-input v-model="inputs.input4" class="mb-4" :disabled="!allowEditing" />

      <p class="mb-2">
        e) <number-value :value="number5" unit="\text{mm}" />
        <stemble-latex content="$\text{ to nm}$" />
      </p>
      <calculation-input v-model="inputs.input5" class="mb-4" :disabled="!allowEditing" />

      <p class="mb-2">
        f) <number-value :value="number6" unit="\text{s}" />
        <stemble-latex content="$\text{ to ms}$" />
      </p>
      <calculation-input v-model="inputs.input6" :disabled="!allowEditing" />
    </v-form>
  </div>
</template>

<script>
import DynamicQuestionMixin from '../../mixins/dynamic-question';
import CalculationInput from '../inputs/CalculationInput';
import StembleLatex from '@/tasks/components/StembleLatex';
import NumberValue from '../NumberValue';

export default {
  name: 'Question184',
  components: {
    StembleLatex,
    CalculationInput,
    NumberValue,
  },
  mixins: [DynamicQuestionMixin()],
  data() {
    return {
      inputs: {
        input1: null,
        input2: null,
        input3: null,
        input4: null,
        input5: null,
        input6: null,
      },
    };
  },
  computed: {
    number1() {
      return this.taskState.getValueBySymbol('number1').content;
    },
    number2() {
      return this.taskState.getValueBySymbol('number2').content;
    },
    number3() {
      return this.taskState.getValueBySymbol('number3').content;
    },
    number4() {
      return this.taskState.getValueBySymbol('number4').content;
    },
    number5() {
      return this.taskState.getValueBySymbol('number5').content;
    },
    number6() {
      return this.taskState.getValueBySymbol('number6').content;
    },
  },
};
</script>
